// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-cases-[module]-[topic]-js": () => import("./../../../src/pages/cases/[module]/[topic].js" /* webpackChunkName: "component---src-pages-cases-[module]-[topic]-js" */),
  "component---src-pages-cases-[module]-index-js": () => import("./../../../src/pages/cases/[module]/index.js" /* webpackChunkName: "component---src-pages-cases-[module]-index-js" */),
  "component---src-pages-cases-index-js": () => import("./../../../src/pages/cases/index.js" /* webpackChunkName: "component---src-pages-cases-index-js" */),
  "component---src-pages-footer-js": () => import("./../../../src/pages/footer.js" /* webpackChunkName: "component---src-pages-footer-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-jsonfix-js": () => import("./../../../src/pages/jsonfix.js" /* webpackChunkName: "component---src-pages-jsonfix-js" */),
  "component---src-pages-mcqs-[module]-[topic]-js": () => import("./../../../src/pages/mcqs/[module]/[topic].js" /* webpackChunkName: "component---src-pages-mcqs-[module]-[topic]-js" */),
  "component---src-pages-mcqs-[module]-index-js": () => import("./../../../src/pages/mcqs/[module]/index.js" /* webpackChunkName: "component---src-pages-mcqs-[module]-index-js" */),
  "component---src-pages-mcqs-index-js": () => import("./../../../src/pages/mcqs/index.js" /* webpackChunkName: "component---src-pages-mcqs-index-js" */),
  "component---src-pages-muneebakhtar-01-lsggb-dashboard-js": () => import("./../../../src/pages/muneebakhtar01lsggb/dashboard.js" /* webpackChunkName: "component---src-pages-muneebakhtar-01-lsggb-dashboard-js" */),
  "component---src-pages-page-2-js": () => import("./../../../src/pages/page-2.js" /* webpackChunkName: "component---src-pages-page-2-js" */),
  "component---src-pages-smart-sets-[module]-[topic]-js": () => import("./../../../src/pages/smart-sets/[module]/[topic].js" /* webpackChunkName: "component---src-pages-smart-sets-[module]-[topic]-js" */),
  "component---src-pages-smart-sets-[module]-index-js": () => import("./../../../src/pages/smart-sets/[module]/index.js" /* webpackChunkName: "component---src-pages-smart-sets-[module]-index-js" */),
  "component---src-pages-smart-sets-index-js": () => import("./../../../src/pages/smart-sets/index.js" /* webpackChunkName: "component---src-pages-smart-sets-index-js" */),
  "component---src-pages-using-typescript-tsx": () => import("./../../../src/pages/using-typescript.tsx" /* webpackChunkName: "component---src-pages-using-typescript-tsx" */),
  "component---src-pages-usmle-[module]-[topic]-js": () => import("./../../../src/pages/usmle/[module]/[topic].js" /* webpackChunkName: "component---src-pages-usmle-[module]-[topic]-js" */),
  "component---src-pages-usmle-index-js": () => import("./../../../src/pages/usmle/index.js" /* webpackChunkName: "component---src-pages-usmle-index-js" */),
  "component---src-pages-usmle-tree-index-tsx": () => import("./../../../src/pages/usmle/tree/index.tsx" /* webpackChunkName: "component---src-pages-usmle-tree-index-tsx" */),
  "component---src-pages-usmle-uworldexp-js": () => import("./../../../src/pages/usmle/uworldexp.js" /* webpackChunkName: "component---src-pages-usmle-uworldexp-js" */),
  "component---src-pages-vqs-[module]-[topic]-js": () => import("./../../../src/pages/vqs/[module]/[topic].js" /* webpackChunkName: "component---src-pages-vqs-[module]-[topic]-js" */),
  "component---src-pages-vqs-[module]-index-js": () => import("./../../../src/pages/vqs/[module]/index.js" /* webpackChunkName: "component---src-pages-vqs-[module]-index-js" */),
  "component---src-pages-vqs-index-js": () => import("./../../../src/pages/vqs/index.js" /* webpackChunkName: "component---src-pages-vqs-index-js" */)
}

